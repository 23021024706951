import {Slot} from '@radix-ui/react-slot';
import {cva, type VariantProps} from 'class-variance-authority';
import {AnimatePresence, motion} from 'framer-motion';
import {LucideIcon} from 'lucide-react';
import * as React from 'react';
import {cn} from '~/components/utils';
import {useMediaQuery} from '../hooks/use-media-query';
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from './tooltip';

const buttonVariants = cva(
  'flex cursor-pointer flex-row items-center justify-center ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        primary: 'bg-primary text-card hover:bg-primary/90',
        destructive: 'bg-destructive text-card hover:bg-destructive/90',
        outline: 'border border-input bg-background hover:bg-accent text-foreground',
        secondary: 'bg-secondary text-card hover:bg-secondary/80',
        tertiary: 'bg-tertiary text-card hover:bg-tertiary/80',
        quaternary: 'bg-quaternary text-card hover:bg-quaternary/80',
        ghost: 'hover:bg-accent',
        link: 'text-primary underline-offset-4 hover:underline',
        translucent: 'hover:bg-foreground/10',
        'destructive-outline': 'border border-destructive text-destructive hover:bg-destructive-foreground',
        'primary-outline': 'border border-primary text-primary hover:bg-primary/20',
        'secondary-outline': 'border border-secondary text-secondary hover:bg-secondary/10',
        'tertiary-outline': 'border border-tertiary text-tertiary hover:bg-tertiary/10',
        'quaternary-outline': 'border border-quaternary text-quaternary hover:bg-quaternary/10',
      },
      size: {
        xs: 'h-6 rounded-md px-1 text-xs font-medium',
        default: 'h-10 px-4 rounded-md py-2 text-sm font-medium',
        sm: 'h-9 rounded-md px-3 text-sm font-medium',
        lg: 'h-11 rounded-md px-8 text-sm font-medium',
        icon: 'h-10 w-10 rounded-md',
        'icon-rounded': 'h-10 w-10 rounded-full',
        'icon-rounded-sm': 'h-8 w-8 rounded-full',
        badge: 'rounded-full px-2.5 py-0.5 text-sm',
        'icon-xl': 'h-14 w-14 rounded-full',
        'lg-rounded': 'h-11 rounded-full px-4 text-sm font-medium',
        xl: 'h-16 rounded-2xl px-6 text-2xl font-medium',
        'xl-rounded': 'h-14 rounded-full px-4 text-lg font-medium',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default',
    },
  },
);

export const iconButtonVariants = cva('', {
  variants: {
    size: {
      xs: 'size-2',
      default: 'size-4',
      sm: 'size-4',
      lg: 'size-5',
      icon: 'size-6',
      'icon-rounded': 'size-6',
      'icon-rounded-sm': 'size-4',
      badge: 'size-4',
      'lg-rounded': 'size-5',
      'xl-rounded': 'size-6',
      'icon-xl': 'size-6',
      xl: 'size-6',
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  responsive?: boolean;
  iconLeft?: LucideIcon | ((props: {className: string}) => JSX.Element);
  iconRight?: LucideIcon | ((props: {className: string}) => JSX.Element);
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      children,
      responsive,
      iconLeft: IconLeft,
      iconRight: IconRight,
      ...props
    },
    ref,
  ) => {
    const [expanded, setExpanded] = React.useState(false);
    const isXl = useMediaQuery('(min-width: 1536px)');
    if (asChild) {
      return (
        <Slot className={cn(buttonVariants({variant, size, className}))} ref={ref} {...props}>
          {children}
        </Slot>
      );
    }

    return (
      <button
        className={cn(buttonVariants({variant, size, className}))}
        ref={ref}
        {...props}
        onMouseEnter={() => setExpanded(true)}
        onMouseLeave={() => setExpanded(false)}>
        {!!IconLeft && <IconLeft className={cn(iconButtonVariants({size}))} />}
        {!!children && (
          <AnimatePresence initial={false}>
            {(!responsive || isXl || expanded) && (
              <motion.span
                className="overflow-hidden whitespace-nowrap"
                initial={{width: 0, marginRight: 0, marginLeft: 0}}
                animate={{width: 'auto', marginRight: 8, marginLeft: 8}}
                exit={{width: 0, marginRight: 0, marginLeft: 0}}>
                {children}
              </motion.span>
            )}
          </AnimatePresence>
        )}
        {!!IconRight && <IconRight className={cn(iconButtonVariants({size}))} />}
      </button>
    );
  },
);
Button.displayName = 'Button';

const ButtonWithTooltip = ({
  children,
  tooltipContent,
  delayDuration,
  ...props
}: ButtonProps & {tooltipContent: React.ReactNode; delayDuration?: number}) => {
  return (
    <TooltipProvider delayDuration={delayDuration}>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button {...props}>{children}</Button>
        </TooltipTrigger>
        <TooltipContent>{tooltipContent}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
ButtonWithTooltip.displayName = 'ButtonWithTooltip';

export {Button, buttonVariants, ButtonWithTooltip};
